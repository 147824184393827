import React from "react";
import { Input } from "./Input";
import { useMemo, useState } from "react";

const IPv4 =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const IPv6 =
    /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;

const IpInput = ({ handleChange, ip, isEditing, style }) => {
    const [curIp, setIp] = useState(ip.ip);
    const isValid = useMemo(() => {
        return !curIp.trim() || !!curIp.match(IPv4) || !!curIp.match(IPv6);
    }, [curIp]);

    const handleIpChange = (event) => {
        setIp(event.target.value);
        handleChange(event);
    };

    return (
        <Input
            type="text"
            name={`ip[${ip.id}]`}
            placeholder=" "
            value={curIp}
            handleChange={handleIpChange}
            disabled={!isEditing}
            hasError={!isValid}
            hint="Invalid IP address"
            style={{ display: "flex", ...(style ? style : {}) }}
            autocomplete="off"
        />
    );
};

export { IpInput };
