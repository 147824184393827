import { useForm } from "react-hook-form";
import React, { Component, useCallback, useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { v4 as uuid } from "uuid";

import withLayout from "../../layout";
import { withAppContext } from "../../components/withAppContext";

import { Input } from "../../components/Form/Input";
import {
    apiFetchProfile,
    apiSafeSearchList,
    apiUpdateProfile,
} from "../../util/api";
import { registerTypes } from "../../components/Register";
import { IpInput } from "../../components/Form/IpInput";
import { PortalSettings } from "../../components/PortalSettings/portal-settings";
import { adminApiPortalList } from "../../util/adminApi";
import { AccessibilityMenu } from "../../components/AccessibilityMenu/AccessibilityMenu";

const SettingsPage = ({ appContext, intl: { formatMessage } }) => {
    const [resetCounter, setResetCounter] = useState(0);

    /**@type {ReactExt.State<API.User>} */
    const [user, setUser] = useState(appContext.user);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            ageXml: user.ageXml,
            apiOnTheFly: user.apiOnTheFly,
        },
    });

    /**@type {ReactExt.State<boolean>}*/
    const [ageXml, setAgeXml] = useState(user.ageXml);

    /**@type {ReactExt.State<boolean>}*/
    const [apiOnTheFly, setApiOnTheFly] = useState(user.apiOnTheFly);

    /**@type {ReactExt.State<API.IpObject[]>}*/
    const [ips, setIps] = useState(
        user.ips
            .filter(({ dyndns }) => !dyndns)
            .map((ip) => ({
                ...ip,
                uuid: uuid(),
            })),
    );

    /**@type {ReactExt.State<API.IpObject[]>}*/
    const [dynamicIps, setDynamicIps] = useState(
        user.ips
            .filter(({ dyndns }) => dyndns)
            .map((ip) => ({
                ...ip,
                uuid: uuid(),
            })),
    );

    const [portals, setPortals] = useState([]);
    const [safeSearches, setSafeSearches] = useState([]);

    useEffect(() => {
        (async () => {
            const newProfile = await apiFetchProfile("me");
            const availableSafeSearches = await apiSafeSearchList();
            const availablePortals = await adminApiPortalList();

            setUser(newProfile);
            setIps(
                newProfile.ips
                    .filter(({ dyndns }) => !dyndns)
                    .map((ip) => ({
                        ...ip,
                        uuid: uuid(),
                    })),
            );
            setDynamicIps(
                newProfile.ips
                    .filter(({ dyndns }) => dyndns)
                    .map((ip) => ({
                        ...ip,
                        uuid: uuid(),
                    })),
            );

            setAgeXml(newProfile.ageXml);
            setApiOnTheFly(newProfile.apiOnTheFly);

            setPortals(availablePortals);
            setSafeSearches(availableSafeSearches);
        })();
    }, [resetCounter, setUser, setPortals, setSafeSearches]);

    const resetForm = useCallback(
        (event) => {
            event.preventDefault();
            window.location.reload(); // fuck this shit
        },
        [setResetCounter],
    );

    const submit = useCallback(
        async ({ ageXml, apiOnTheFly }) => {
            const profile = {
                ...user,
                ageXml,
                apiOnTheFly,
                ips: [...ips, ...dynamicIps].filter(({ ip }) => !!ip.trim()),
            };

            await apiUpdateProfile(profile);
            appContext.changeUserState(profile);
            setResetCounter((prev) => prev + 1);
        },
        [user, ips, dynamicIps],
    );

    return (
        <>
            <AccessibilityMenu i18nKey="settings" />
            <form
                method="post"
                onSubmit={handleSubmit(submit)}
                onReset={resetForm}
            >
                <h4 className="form-label">
                    <FormattedMessage id={"profile.ip_addresses"} />
                </h4>

                <div
                    dangerouslySetInnerHTML={{
                        __html: `<p>${formatMessage({
                            id: "profile.ip_addresses_info",
                        })}</p>`,
                    }}
                />

                {dynamicIps.length > 0 && (
                    <div className="form-group">
                        <label>DynDNS IPs</label>
                        {dynamicIps.map((ip) => (
                            <span
                                key={ip.uuid}
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    rowGap: "10px",
                                }}
                            >
                                <IpInput
                                    ip={ip}
                                    style={{
                                        width: "100%",
                                        marginRight: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                    handleChange={(e) => void 0}
                                    isEditing={false}
                                />
                                <button
                                    type="button"
                                    className="btn btn-error btn-sm"
                                    style={{
                                        paddingBlock: "5px",
                                        height: "100%",
                                    }}
                                    onClick={() =>
                                        setDynamicIps((old) =>
                                            old.filter(
                                                ({ uuid }) => uuid !== ip.uuid,
                                            ),
                                        )
                                    }
                                >
                                    Delete
                                </button>
                            </span>
                        ))}
                    </div>
                )}

                <div
                    className="form-group"
                    style={{ display: "flex", flexDirection: "column" }}
                >
                    <br />
                    <label>Static IPs</label>
                    {ips.map((ip) => (
                        <span
                            key={ip.uuid}
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                rowGap: "10px",
                            }}
                        >
                            <IpInput
                                ip={ip}
                                style={{
                                    width: "100%",
                                    marginRight: "10px",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                handleChange={(e) => {
                                    const value = e.target.value;
                                    setIps((old) =>
                                        old.map(({ uuid, ...rest }) => {
                                            if (uuid === ip.uuid) {
                                                return {
                                                    uuid,
                                                    ...rest,
                                                    ip: value,
                                                };
                                            }
                                            return { uuid, ...rest };
                                        }),
                                    );
                                }}
                                isEditing={true}
                            />
                            <button
                                type="button"
                                className="btn btn-error btn-sm"
                                style={{ paddingBlock: "5px", height: "100%" }}
                                onClick={() =>
                                    setIps((old) =>
                                        old.filter(
                                            ({ uuid }) => uuid !== ip.uuid,
                                        ),
                                    )
                                }
                            >
                                Delete
                            </button>
                        </span>
                    ))}
                    <button
                        type="button"
                        onClick={() =>
                            setIps((old) => [
                                ...old,
                                { ip: "", dyndns: false, uuid: uuid() },
                            ])
                        }
                    >
                        Add
                    </button>
                </div>

                <h4>
                    <FormattedMessage id="profile.uses" />
                </h4>
                <div className="form-group">
                    <br />
                    <label className="form-label">
                        <input
                            type="checkbox"
                            name="ageXml"
                            defaultChecked={ageXml}
                            {...register("ageXml")}
                        />
                        <FormattedMessage id="settings.use_age_xml" />
                    </label>
                </div>

                <div className="form-group">
                    <label className="form-label">
                        <input
                            type="checkbox"
                            name="apiOnTheFly"
                            defaultChecked={apiOnTheFly}
                            {...register("apiOnTheFly")}
                        />
                        <FormattedMessage id="settings.use_otf" />
                    </label>
                </div>

                {portals.length > 0 && (
                    <>
                        <br />
                        <h4>
                            <FormattedMessage id="portals.portals" />
                        </h4>
                        {portals.map((portal) => (
                            <PortalSettings
                                key={portal.name}
                                name={portal.name}
                                allowed={
                                    user.portals.length &&
                                    user.portals.find(
                                        ({ name }) => name === portal.name,
                                    ) &&
                                    user.portals.find(
                                        ({ name }) => name === portal.name,
                                    ).active
                                }
                                safeSearch={
                                    user.portals.length &&
                                    user.portals.find(
                                        ({ name }) => name === portal.name,
                                    ) &&
                                    user.portals.find(
                                        ({ name }) => name === portal.name,
                                    ).safeSearch
                                }
                                age={portal.age}
                                globalAge={12}
                                hasSafeSearch={portal.safeSearches.length > 0}
                                text={portal.text}
                                toggleAllowed={() => {
                                    setUser((old) => {
                                        const newPortals = old.portals.map(
                                            (p) => {
                                                if (p.name === portal.name) {
                                                    return {
                                                        ...p,
                                                        active: !p.active,
                                                    };
                                                }
                                                return p;
                                            },
                                        );
                                        return { ...old, portals: newPortals };
                                    });
                                }}
                                toggleSafeSearch={() => {
                                    setUser((old) => {
                                        const newPortals = old.portals.map(
                                            (p) => {
                                                if (p.name === portal.name) {
                                                    return {
                                                        ...p,
                                                        safeSearch:
                                                            !p.safeSearch,
                                                    };
                                                }
                                                return p;
                                            },
                                        );
                                        return { ...old, portals: newPortals };
                                    });
                                }}
                            />
                        ))}
                    </>
                )}

                <button type="reset" className="btn mr-2">
                    <FormattedMessage id="profile.reset" />
                </button>
                <button type="submit" className="btn btn-primary ml-2">
                    <FormattedMessage id="profile.save" />
                </button>
            </form>
        </>
    );
};

const customProps = {
    localeKey: "dashboard",
    withAuth: true,
    title: <FormattedMessage id="profile.title" />,
};

export default withLayout(customProps)(
    withAppContext(injectIntl(SettingsPage)),
);
